import { React, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, Switch, CircularProgress, Backdrop, Autocomplete, TextField, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, List, ListItem, Typography
} from '@mui/material';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { toast } from 'react-toastify';
import axios from 'axios';

import { socket } from './socket';

var api = axios.create({ withCredentials: true });

function Users(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [customer_list, setCustomerList] = useState([]);
    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null);
    const user = props.user;
    const isConnected = props.isConnected;
    const [openAdd, setAddOpen] = useState(false);
    const [addEmail, setAddEmail] = useState(null);
    const [addFname, setAddFname] = useState(null);
    const [addLname, setAddLname] = useState(null);
    const [addCompany, setAddCompany] = useState(null);
    const [addAdmin, setAddAdmin] = useState(false);
    const [openDelete, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteAuth, setDeleteAuth] = useState(null);

    const handleAddClickOpen = () => {
        setAddOpen(true);
    };
    const handleAddClose = () => {
        setAddOpen(false);
    };

    function addNewUser() {
        if (
            addEmail !== null
            && addFname !== null
            && addLname !== null
            && addCompany !== null
            && addAdmin !== null
        ) {
            var company = [addCompany];
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(`${process.env.REACT_APP_BASEURL}/api/users/adduser`,
                    {
                        email: addEmail,
                        fname: addFname,
                        lname: addLname,
                        company: company,
                        admin: addAdmin
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                ).then((response) => {
                    setAddOpen(false);
                    toast.success(`User Added.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        setAddOpen(false);
                        toast.error(err.response.data);
                    }
                });
            });
        } else {
            toast.error('All Fields are Required.');
        }
    }

    const handleDeleteClickOpen = () => {
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    function deleteUser(id, auth0) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(`${process.env.REACT_APP_BASEURL}/api/users/delete/${id}/${auth0}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            ).then((response) => {
                setDeleteId(null);
                setDeleteAuth(null);
                setDeleteOpen(false);
                toast.success(`User Deleted.`);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    setDeleteId(null);
                    setDeleteAuth(null);
                    setDeleteOpen(false);
                    toast.error(err.response.data);
                }
            });
        });
    }

    function toggleAdmin(id, admin, email) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(`${process.env.REACT_APP_BASEURL}/api/users/admin/${admin}/${id}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            ).then((response) => {
                toast.success(`Admin status updated.`);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    toast.error(err.response.data);
                }
            });
        });
    }

    function updateCompany(id, company) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(`${process.env.REACT_APP_BASEURL}/api/users/company/${id}`,
                { company: [company] },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            ).then((response) => {
                toast.success(`Vendor updated.`);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    toast.error(err.response.data);
                }
            });
        });
    }

    function getUsers() {
        if (customer_list.length > 0) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.get(`${process.env.REACT_APP_BASEURL}/api/users/all`,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                ).then((response) => {
                    var c = [
                        { field: 'col0', headerName: 'First Name', flex: .25, minWidth: 100, type: 'string' },
                        { field: 'col1', headerName: 'Last Name', flex: .25, minWidth: 100, type: 'string' },
                        { field: 'col2', headerName: 'Email', flex: .25, minWidth: 100, type: 'string' },
                        {
                            field: 'col3', headerName: 'Vendor', flex: .25, minWidth: 325, display: 'flex',
                            renderCell: (params) => {
                                return (
                                    <Autocomplete
                                        options={customer_list}
                                        getOptionLabel={(option) => option.bought_from_name}
                                        getOptionKey={(option) => option.buyer_broker_id}
                                        defaultValue={params.value[0]}
                                        disableClearable={true}
                                        onChange={(event, newValue) => {
                                            updateCompany(params.row.id, newValue.buyer_broker_id);
                                        }}
                                        renderInput={(p) => (
                                            <TextField {...p} />
                                        )}
                                        size="small"
                                        sx={{ minWidth: 300, maxWidth: '95%' }}
                                    />
                                );
                            }
                        },
                        {
                            field: 'col4', headerName: 'Admin', flex: .25, minWidth: 100,
                            renderCell: (params) => {
                                return (
                                    <>
                                        {
                                            user._id === params.row.id
                                                ?
                                                <Switch
                                                    checked={params.value}
                                                    color='success'
                                                    disabled
                                                />
                                                :
                                                <Switch
                                                    checked={params.value}
                                                    color='success'
                                                    onClick={() => {
                                                        toggleAdmin(params.row.id, !params.value);
                                                    }}
                                                />
                                        }
                                    </>
                                );
                            }
                        },
                        {
                            field: 'col5', headerName: 'Delete', flex: .25, minWidth: 100,
                            renderCell: (params) => {
                                return (
                                    <>
                                        {
                                            user._id === params.row.id
                                                ?
                                                <Button variant='contained' color='error' disabled size='small'>Delete User</Button>
                                                :
                                                <Button variant='contained' color='error' size='small' onClick={() => {
                                                    return;
                                                }}>Delete User</Button>
                                        }
                                    </>
                                );
                            }
                        },
                    ];
                    setColumns(c);

                    var r = [];
                    response.data.forEach((row) => {
                        r.push({
                            id: row._id,
                            col0: row.fname,
                            col1: row.lname,
                            col2: row.email,
                            col3: row.company[0] === 'all'
                                ?
                                customer_list.filter(function (entry) { return entry.buyer_broker_id === row.company[0]; })
                                :
                                customer_list.filter(function (entry) { return entry.buyer_broker_id === Number(row.company[0]); }),
                            col4: row.admin,
                            col5: row.auth0
                        });
                    });
                    setRows(r);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                    }
                });
            });
        }
    }

    function getCustomers() {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                `${process.env.REACT_APP_BASEURL}/api/dashboard/customers`,
                { company: ['all'] },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var tmp = [];
                tmp.push({ buyer_broker_id: 'all', bought_from_name: 'Show All' });
                response.data.forEach((record) => {
                    tmp.push(record._id)
                });
                setCustomerList(tmp);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                }
            });
        });
    }

    useEffect(() => {
        getCustomers();
    }, []);

    useEffect(() => {
        if (isConnected) {
            getUsers();
            socket.on('users', () => { getUsers(); });
        }

        return () => {
            socket.off('users');
        };
    }, [isConnected, customer_list.length]);

    if (user.admin) {
        if (columns !== null && rows !== null) {
            return (
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 10000000 }}
                        open={false}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Dialog
                        open={openAdd}
                        onClose={handleAddClose}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogContent>
                            <List>
                                <ListItem>
                                    <TextField variant="standard" label='Email' onChange={(event) => { setAddEmail(event.target.value) }} sx={{ width: '100%' }} />
                                </ListItem>
                                <ListItem>
                                    <TextField variant="standard" label='First Name' onChange={(event) => { setAddFname(event.target.value) }} sx={{ width: '100%' }} />
                                </ListItem>
                                <ListItem>
                                    <TextField variant="standard" label='Last Name' onChange={(event) => { setAddLname(event.target.value) }} sx={{ width: '100%' }} />
                                </ListItem>
                                <ListItem />
                                <ListItem>
                                    <Autocomplete
                                        options={customer_list}
                                        getOptionLabel={(option) => option.bought_from_name}
                                        getOptionKey={(option) => option.buyer_broker_id}
                                        disableClearable={true}
                                        onChange={(event, newValue) => {
                                            setAddCompany(newValue.buyer_broker_id);
                                        }}
                                        renderInput={(p) => (
                                            <TextField {...p} label="Vendor Search" />
                                        )}
                                        size="small"
                                        sx={{ width: '100%' }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <Typography variant='subtitle2'>Admin?</Typography>
                                    <Switch
                                        checked={addAdmin}
                                        color='success'
                                        onClick={() => {
                                            setAddAdmin(!addAdmin);
                                        }}
                                        sx={{ marginRight: '5px' }}
                                    />
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddClose}>Cancel</Button>
                            <Button onClick={() => { addNewUser(); }}>Add User</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openDelete}
                        onClose={handleDeleteClose}
                    >
                        <DialogContent>
                            <DialogContentText>Are you sure you want to delete this user account?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClose} autoFocus>Cancel</Button>
                            <Button color="error" onClick={() => { deleteUser(deleteId, deleteAuth); }}>Delete User Account</Button>
                        </DialogActions>
                    </Dialog>
                    <Box flexGrow={1} sx={{ padding: '20px' }}>
                        <Button onClick={() => { return; }}>Add New User</Button>
                        <div style={{ height: '80vh', width: '100%' }}>
                            <DataGridPro
                                slots={{
                                    toolbar: GridToolbar
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 }
                                    }
                                }}
                                rows={rows}
                                columns={columns}
                                pagination
                            />
                        </div>
                    </Box>
                </>
            );
        } else {
            return (
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
                >
                    <CircularProgress size={60} thickness={4} />
                </Box>
            );
        }
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <WarningRoundedIcon size={60} thickness={4} />
                <p>Not Authorized</p>
            </Box>
        );
    }
}

export default Users;