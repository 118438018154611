import { React, useEffect, useRef, useState, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, Paper, CircularProgress, Backdrop, Slide, Dialog, AppBar, Toolbar, IconButton, Typography, DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';

import { socket } from './socket';

import Events from './events';

var api = axios.create({ withCredentials: true });

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

function DataGrid(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [broker_loading, setBrokerLoading] = useState(true);
    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null);
    const current_broker = props.current_broker;
    const previous_broker = usePrevious(current_broker);
    const isConnected = props.isConnected;
    const [openDialog, setDialogOpen] = useState(false);
    const [event_id, setEventId] = useState(null);
    const [clicked_event, setClickedEvent] = useState('Event Details');

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setClickedEvent('Event Details')
    };

    function getData(controller) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(`${process.env.REACT_APP_BASEURL}/api/datagrid/events_future/${current_broker}`,
                {
                    signal: controller.signal, headers: { Authorization: `Bearer ${token}` }
                }
            ).then((response) => {
                var c = [
                    { field: 'col0', headerName: 'Event', flex: .5, minWidth: 100, type: 'string' },
                    { field: 'col1', headerName: 'Date', flex: .35, minWidth: 100, type: 'dateTime' },
                    { field: 'col2', headerName: 'Venue', flex: .5, minWidth: 100, type: 'string' },
                    { field: 'col3', headerName: 'Open Tickets', flex: .25, minWidth: 100, type: 'number' },
                    { field: 'col4', headerName: 'Sold Tickets', flex: .25, minWidth: 100, type: 'number' },
                    {
                        field: 'col5', headerName: 'Total Cost', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'col6', headerName: 'Total Sales', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'today', headerName: 'Sold Today', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'three_day', headerName: 'Sold Last 3D', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'seven_day', headerName: 'Sold Last 7D', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'two_week', headerName: 'Sold Last 14D', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'one_month', headerName: 'Sold Last 30D', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'avg', headerName: 'Avg Ticket', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'low', headerName: 'Low Ticket', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'col7', headerName: 'Total Profit', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'col8', headerName: 'Profit %', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}%`;
                        }
                    },
                ];
                setColumns(c);

                var r = [];
                response.data.forEach((row, i) => {
                    r.push({
                        id: i,
                        col0: row._id.event_name,
                        col1: dayjs(row._id.event_datetime.replace(/[Z]$/g, '')).toDate(),
                        col2: row._id.venue_name,
                        col3: row.hasOwnProperty('open_tickets') ? row.open_tickets.open_tickets_count : 0,
                        col4: row.hasOwnProperty('sold_tickets') ? row.sold_tickets.sold_tickets_count : 0,
                        col5: row.total_cost,
                        col6: row.hasOwnProperty('sold_tickets') ? row.sold_tickets.sold_total : 0,
                        avg: row.hasOwnProperty('sold_tickets') ? row.sold_tickets.avg_ticket : 0,
                        low: row.hasOwnProperty('sold_tickets') ? row.sold_tickets.low_ticket : 0,
                        col7: row.hasOwnProperty('sold_tickets') ? row.sold_tickets.sold_total - row.total_cost : 0 - row.total_cost,
                        col8: row.hasOwnProperty('sold_tickets') ? (((row.sold_tickets.sold_total - row.total_cost) / row.total_cost) * 100) : row.total_cost !== 0 ? -100 : 0,
                        event_id: row._id.event_id,
                        today: row.hasOwnProperty('one_month') ? numberFormatter(row.one_month.filter(({ _id }) => dayjs().diff(_id, 'day') <= 1).reduce((n, { count }) => n + count, 0)) : 0,
                        three_day: row.hasOwnProperty('one_month') ? numberFormatter(row.one_month.filter(({ _id }) => dayjs().diff(_id, 'day') <= 3).reduce((n, { count }) => n + count, 0)) : 0,
                        seven_day: row.hasOwnProperty('one_month') ? numberFormatter(row.one_month.filter(({ _id }) => dayjs().diff(_id, 'day') <= 7).reduce((n, { count }) => n + count, 0)) : 0,
                        two_week: row.hasOwnProperty('one_month') ? numberFormatter(row.one_month.filter(({ _id }) => dayjs().diff(_id, 'day') <= 14).reduce((n, { count }) => n + count, 0)) : 0,
                        one_month: row.hasOwnProperty('one_month') ? numberFormatter(row.one_month.reduce((n, { count }) => n + count, 0)) : 0,
                    });
                });
                setRows(r);
                setBrokerLoading(false);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                    setBrokerLoading(false);
                }
            });
        });
    }

    const handleRowClick = (params) => {
        setEventId(params.row.event_id);
        setClickedEvent(params.row.col0 + ' @ ' + params.row.col2 + ' | ' + dayjs(params.row.col1).format('MM/DD/YYYY, hh:mmA'));
        handleDialogClickOpen();
    };

    useEffect(() => {
        const controller = new AbortController();

        if (isConnected) {
            if (previous_broker !== current_broker) {
                setBrokerLoading(true);
            }

            getData(controller);

            socket.on('data', () => { getData(controller); });
        }

        return () => {
            socket.off('data');
            controller.abort();
        };
    }, [isConnected, current_broker]);

    if (columns !== null && rows !== null) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 10000000 }}
                    open={broker_loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleDialogClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDialogClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {clicked_event}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Events isConnected={isConnected} event_id={event_id} current_broker={current_broker} />
                    </DialogContent>
                </Dialog>
                <Paper elevation={1}>
                    <div style={{ height: '78.5vh', width: '100%' }}>
                        <DataGridPro
                            slots={{
                                toolbar: GridToolbar
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 }
                                }
                            }}
                            onRowClick={handleRowClick}
                            rows={rows}
                            columns={columns}
                            pagination
                        />
                    </div>
                </Paper>
            </>
        );
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }
}

export default DataGrid;